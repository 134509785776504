export default (apiURL, methods) => {
    return {
        checkRegistrationCode(params = {}) {
            // NOTE: action=validation_code_get
            const url = `${apiURL}/user/registration-code`;

            return methods.get3(url, {
                secret: params.secret,
            });
        },

        confirmRegistration(params = {}) {
            // NOTE: action=user_set_password
            const url = `${apiURL}/user/password/confirm`;

            return methods.post(url, {
                secret: params.secret,
                password: params.password,
            });
        },

        /* --- */

        signIn(params = {}) {
            // NOTE: action=get_session
            const url = `${apiURL}/session`;

            return methods.post(url, {
                username: params.username,
                password: params.password,
            });
        },

        signInBySecret(params = {}) {
            // NOTE: action=sign_in_get_session_by_secret_code
            const url = `${apiURL}/session/by-secret`;

            return methods.post(url, {
                username: params.username || "",
                secret_code: params.secretCode || "",
                email_code: params.emailCode || "",
            });
        },

        signOut(params = {}) {
            // NOTE: action=kill_session
            const url = `${apiURL}/session`;

            return methods.del(url, {
                session_id: params.session,
            });
        },

        checkSession(params = {}) {
            // NOTE: action=check_session
            const url = `${apiURL}/session`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        newLocation(params = {}) {
            // NOTE: action=new_location_detected
            console.log("in newLocation function")
            const url = `${apiURL}/session/location/new`;
            console.log("here is url")
            console.log(url)

            return methods.get3(url, {
                session_id: params.session || "",
            });
        },

        changePassword(params = {}) {
            // NOTE: action=user_update_password
            const url = `${apiURL}/user/password`;

            return methods.post(url, {
                session_id: params.session,
                password: params.password,
            });
        },

        resetPassword(params = {}) {
            // NOTE: action=user_reset_password
            const url = `${apiURL}/user/password/reset`;

            return methods.get(url, {
                username: params.username,
            });
        },

        /* --- */

        updateEmail(params = {}) {
            // NOTE: action=user_update_email
            const url = `${apiURL}/user/email`;

            return methods.post(url, {
                session_id: params.session,
                email: params.email,
            });
        },

        updateBadEmail(params = {}) {
            // NOTE: action=update_bad_email
            const url = `${apiURL}/user/email/bad`;

            return methods.post(url, {
                session_id: params.session,
                email: params.email,
            });
        },

        confirmBadEmail(params = {}) {
            // NOTE: action=confirm_bad_email
            const url = `${apiURL}/user/email/bad/confirm`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        /* --- */

        updateFullName(params = {}) {
            // NOTE: action=user_update_fullname
            const url = `${apiURL}/user/fullname`;

            return methods.post(url, {
                session_id: params.session,
                fullname: params.fullName,
            });
        },

        updateUserSettings(params = {}) {
            // NOTE: action=user_set_settings
            const url = `${apiURL}/user/settings`;

            return methods.post(url, {
                session_id: params.session,
                key: params.key,
                value: params.value,
            });
        },

        userSetAvatar(params = {}) {
            // NOTE: action=user_set_avatar
            const url = `${apiURL}/user/avatar`;

            return methods.postForm(url, {
                session_id: params.session,
                file: params.file,
            });
        },

        userDeleteAvatar(params = {}) {
            // NOTE: action=user_delete_avatar
            const url = `${apiURL}/user/avatar/delete`;

            return methods.post(url, {
                session_id: params.session,
            });
        },

        /* --- */

        getOptions(params = {}) {
            // NOTE: action=get_options
            const url = `${apiURL}/user/options`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        getOption(params = {}) {
            // NOTE: action=get_option
            const url = `${apiURL}/user/options/${params.key}`;

            return methods.get3(url, {
                session_id: params.session,
            });
        },

        setOption(params = {}) {
            // NOTE: action=set_option
            const url = `${apiURL}/user/options/${params.key}/set`;

            return methods.get(url, {
                session_id: params.session,
                value: params.value,
            });
        },

        /* --- */

        newsletterUnsubscribe(params = {}) {
            // NOTE: action=unsubscribe_from_newsletter
            const url = `${apiURL}/user/newsletter/unsubscribe`;

            return methods.get3(url, {
                email: params.email,
            });
        },

        /* --- */

        getClassesLimitInfo(params = {}) {
            // NOTE: action=get_user_classes_limit_info
            const url = `${apiURL}/classes/limits`;

            return methods.get(url, {
                session_id: params.session || "",
            });
        },
    };
};
